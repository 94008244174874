
// Prevent up and down keys scrolling (for search box)
window.addEventListener("keydown", function(e) {
    // space and arrow keys
    if([38,40].indexOf(e.keyCode) > -1) {
        e.preventDefault();
    }
}, false);




function getConfig(name) {
    var value = localStorage.getItem(name);
    if (value == 'true') {
        return true;
    } else {
        return false;
    }
}

var config = {
    'nsfw' : getConfig('config_nsfw')
}



var day = new Date().toLocaleString('en-us', {  weekday: 'long' });

var todays_comics = [];

comics.forEach(function(comic) {
    if (typeof comic.schedule !== 'undefined' && comic.schedule.indexOf(day) > -1) {
        todays_comics.push(comic);
    }
});

function getAllBookmarks() {
    var bookmarks = [];
    groups.forEach(function(group) {
        group.bookmarks.forEach(function(bookmark) {
            bookmark.group = group;
            bookmarks.push(bookmark);
        });
    });
    return bookmarks;
}

function getRandomBookmark() {
    var bookmarks = getAllBookmarks();
    var id = Math.floor((Math.random() * bookmarks.length));
    return bookmarks[id];
}

var app = new Vue({
    el: '#app',
    data: function() {
        return {
            'search': '',
            'bookmarks': getAllBookmarks(),
            'todays_comics': todays_comics,
            'groups': groups,
            'random': getRandomBookmark(),
            'config': config
        }
    },
    methods: {
        go: function(event) {
            var query = event.target.value;
            if (query.startsWith("r/")) {
                window.open("https://reddit.com/" + query, "_self");
            } else {
                window.open("https://www.google.co.uk/#q=" + query, "_self");
            }
        },
        focusInitial: function(event) {
            if(document.getElementById("js-filter").firstChild != null) {
                document.getElementById("js-filter").firstChild.focus();
            }
        },
        focusUp: function(event) {
            if (event.target.previousSibling != null) {
                event.target.previousSibling.focus();
            } else {
                document.getElementById("search").focus();
            }
        },
        focusDown: function(event) {
            if (event.target.nextSibling != null) {
                event.target.nextSibling.focus();
            } else {
                document.getElementById("js-filter").firstChild.focus();
            }
        },
        focusBack: function(event) {
            document.getElementById("search").focus();
        },
        openOnHover: function(event) {
            var elements = document.querySelectorAll('.js-openOnHover');
            elements.forEach(function(item) {
                item.classList.remove('is-open');
            });
            event.currentTarget.classList.add('is-open');
        },
        closeAll: function(event) {
            var elements = document.querySelectorAll('.js-openOnHover');
            elements.forEach(function(item) {
                item.classList.remove('is-open');
            });
        },
        openLink: function(bookmark, $event) {
            localStorage.setItem(bookmark.name, new Date().toUTCString());
            window.open(bookmark.url, "_self");
        },
        getOpened: function(bookmark, $event) {
            var item = localStorage.getItem(bookmark.name);
            if(item) {
                var itemdate = new Date(item);
                var date = new Date();
                var diff = (date.getTime() - itemdate.getTime());
                if(diff < 4.32e+7) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        filterNsfw: function(bookmarks) {
            var app = this;
            return bookmarks.filter(function(item){
                if(item.nsfw == true && config.nsfw == false) {
                    return false
                } else {
                    return true;
                }
            })
        },
        configSave: function(name, event) {
            localStorage.setItem(name, event.currentTarget.checked);
        },
        toggleConfig: function(event) {
            document.getElementById("js-config").classList.toggle('is-open');
        }
    },
    computed: {
        filteredBookmarks: function() {
            var app = this;
            return this.bookmarks.filter(function(item){
                if (app.search == '') {
                    return false;
                } else {
                    if (item.name.toLowerCase().indexOf(app.search.toLowerCase()) > -1) {
                        return true;
                    } else if (item.group.name.toLowerCase().indexOf(app.search.toLowerCase()) > -1) {
                        return true;
                    } else {
                        return false;
                    }
                }
            })
        }
    },


})
